import React from "react";
import { useHistory } from "react-router-dom";
import { LinkTextColumnTypes } from "../../../interfaces/table";

const LinkTextColumn: React.FC<LinkTextColumnTypes> = ({ item, meta, onLinkClickHandler }) => {
  const history = useHistory();
  const { obj, key, onClick } = meta;

  const handleClick = (item:any, key:any) => {
    onClick({ history, item });
    !!onLinkClickHandler && onLinkClickHandler(item, key) 
  };

  return (
    <span className="link" onClick={(e) => {e.stopPropagation(); handleClick(item, key)}}>
      {obj
        ? key.map((el: string, index: number) => (
            <span key={index}>{item[obj][el]?.length > 10 ? item[obj][el]?.substring(0,10).concat('...') : item[obj][el]} </span>
          ))
        : item[key]?.length > 10 ? item[key]?.substring(0,10).concat('...') : item[key]}
    </span>
  );
};

export default LinkTextColumn;
