import React, {useEffect, useState} from "react";
import {Route, Switch, Redirect} from "react-router-dom";
import Header from "../../containers/Header";
import Sidebar from "../../containers/Sidebar";
import Users from "../Users";
import Analytics from "../Analytics";
import Meetings from "../Meetings";
import SystemMeetings from "../SystemMeetings";
import SystemMeetingToUsers from "../SystemMeetingToUsers";
import Complaints from "../Complaints";
import {ROUTES} from "../../enum/routes";
import ComplaintDetails from "../ComplaintDetails";
import SystemVariable from "../SystemVariable";
import Supports from "../Tickets/Supports";
import Feedbacks from "../Tickets/Feedbacks";
import Reports from "../Tickets/Reports";
import {useCookie} from "react-use";
import {AUTH_KEY} from "../../enum/auth";
import {useVariables} from "../../service/variables/variables";
import EditProfile from "../EditProfile";
import VAT from "../VAT";
import OverView from "../Overview";
import Document from "../Document";
import {DOCUMENTS_REQUEST} from "../../enum/documents";
import CorporateAccounts from "../CorporateAccounts";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import EmailService from "../EmailService";
import EmailNotificationManagement from "../EmailNotificationManagement";
import VerificationRequests from "../VerificationRequests";
import {COMPLAINT_TYPE_TO_RENDER} from "../../enum/complaint";
import FAQ from "../FAQ";
import SystemErrors from "../SystemErrors";
import RequestsEmailList from "../EmailNotificationManagement/components/requests-email-list";
import PaidContentSales from "../PaidContentSales";
import DBSToWisePayout from "../DBSPayout";
import EditUserProfile from "../EditUserProfile/editProfile";
import SMTP from "../SMTP";
import TemplateEditor from "../TemplateEditor/editor";
import EditorContainer from "../TemplateEditor";


const MainContainer: React.FC = () => {
    const [openSideBar, setOpenSideBar] = useState<boolean>(false);
    const [values] = useCookie(AUTH_KEY)
    const {getVariables} = useVariables()

    useEffect(() => {
        (async () => {
            await getVariables()
        })()
        // eslint-disable-next-line
    }, [])

    if (!values) {
        return <Redirect to={ROUTES.AUTH.LOGIN}/>
    }

    return (
        <div className="main-container">
            <Sidebar openSideBar={openSideBar} setOpenSideBar={setOpenSideBar}/>
            <div className="content-wrapper">
                <Header openSideBar={openSideBar} setOpenSideBar={setOpenSideBar}/>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <div className="page">
                        <Switch>
                            <Route path={ROUTES.PAGES.OVERVIEW} component={OverView} exact/>
                            <Route path={ROUTES.PAGES.USERS} component={Users} exact/>
                            <Route path={ROUTES.PAGES.PROFILE} component={EditProfile} exact/>
                            <Route path={ROUTES.PAGES.EDIT_USER} component={EditUserProfile} exact/>
                            <Route path={ROUTES.PAGES.ANALYTICS} component={Analytics} exact/>
                            <Route path={ROUTES.PAGES.PAID_CONTENT_SALES} component={PaidContentSales} exact/>
                            <Route path={ROUTES.PAGES.DBS_TO_WISE_PAYOUT} component={DBSToWisePayout} exact/>
                            <Route path={ROUTES.PAGES.MEETINGS} component={Meetings} exact/>
                            <Route path={ROUTES.PAGES.SYSTEM_MEETINGS} component={SystemMeetings} exact/>
                            <Route path={ROUTES.PAGES.SYSTEM_MEETING_TO_USERS} component={SystemMeetingToUsers} exact/>
                            <Route path={ROUTES.PAGES.REPORTS} component={Reports} exact/>
                            <Route path={ROUTES.PAGES.SUPPORT} component={Supports} exact/>
                            <Route path={ROUTES.PAGES.FEEDBACK} component={Feedbacks} exact/>
                            <Route path={ROUTES.PAGES.COMPLAINT_DETAILS} component={ComplaintDetails}/>
                            <Route path={ROUTES.PAGES.SYSTEM_VARIABLE} component={SystemVariable} exact/>
                            <Route path={ROUTES.PAGES.VAT} component={VAT} exact/>
                            <Route path={ROUTES.PAGES.SMTP} component={SMTP} exact/>
                            <Route path={ROUTES.PAGES.TEMPLATES} component={TemplateEditor} exact/>
                            <Route path={ROUTES.PAGES.TEMPLATE} component={EditorContainer} exact/>
                            <Route path={ROUTES.PAGES.CORPORATE_ACCOUNTS} component={CorporateAccounts} exact/>
                            <Route path={ROUTES.PAGES.POLICY}
                                   render={() => <Document type={DOCUMENTS_REQUEST.POLICY.value}/>} exact/>
                            <Route path={ROUTES.PAGES.TERMS}
                                   render={() => <Document type={DOCUMENTS_REQUEST.TERMS.value}/>} exact/>
                            <Route path={ROUTES.PAGES.EMAIL_NOTIFICATION_MANAGEMENT} component={EmailNotificationManagement} exact/>
                            <Route path={ROUTES.PAGES.REQUESTS_EMAIL_LIST} component={RequestsEmailList} exact/>
                            <Route path={ROUTES.PAGES.EMAIL_SERVICE} component={EmailService} exact/>
                            <Route path={ROUTES.PAGES.VERIFICATION_REQUESTS} component={VerificationRequests} exact/>
                            <Route path={ROUTES.PAGES.HOSTING_ESCALATION} render={() => <Complaints
                                complaintType={COMPLAINT_TYPE_TO_RENDER.HOSTING_ESCALATION_ROUTINE}/>} exact/>
                            <Route path={ROUTES.PAGES.COMPLAINTS} render={() => <Complaints
                                complaintType={COMPLAINT_TYPE_TO_RENDER.LAUNCHED_THROUGH_COMPLAINT_FORM}/>} exact/>
                            <Route path={ROUTES.PAGES.FAQ} component={FAQ} exact/>
                            <Route path={ROUTES.PAGES.SYSTEM_ERRORS} component={SystemErrors} exact/>
                            <Redirect to={ROUTES.AUTH.LOGIN}/>
                        </Switch>
                    </div>
                </LocalizationProvider>
            </div>
        </div>
    );
};

export default MainContainer;